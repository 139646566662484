/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { BehaviorSubject, pipe, switchMap, tap } from 'rxjs';
import {
  AuthBackendService,
  AuthCookieService,
  BrowserInfoService,
} from '@sybl/feature-auth-data-access';
import { SubscriptionStoreState } from './subscription-store';
import { ISessionResponse } from '@sybl/feature-auth-models';
import {
  IWebSocketMessage,
  WebSocketMessage,
} from '@sybl/feature-websocket-models';
import {
  DocumentStatusEnum,
  IDocumentSaveError,
} from '@sybl/feature-documents-models';
import { UUID } from '@sybl/common-models';
import { ISyblChat, SyblChat } from '@sybl/feature-sybl-models';

export function withSubscriptionStoreMethods() {
  return signalStoreFeature(
    { state: type<SubscriptionStoreState>() },
    withMethods((state) => ({
      selectPrice(name, months) {
        const catalogs = state.catalogProducts();
        
        if(catalogs.length>0){
        const findEntry = catalogs.findIndex(entry=>entry.name===name);
        
        const productDetails = catalogs[findEntry];
        let billingPeriod = '';
        let price:any;
        if(months==12){billingPeriod="MONTHLY"}else{billingPeriod="ANNUAL"}
        
        const findPlanIndex = productDetails.plans.findIndex(plan=>plan.billingPeriod===billingPeriod);

        const plan  = productDetails.plans[findPlanIndex];
        
        const nonTrialPhase = plan.phases.filter(phase=>phase.type!=='TRIAL')[0];
        if(nonTrialPhase!==undefined){
          price = nonTrialPhase.prices[0].value;
        }

        return patchState(state, { 
          selectedPlan: name, 
          licenseType:name,
          months:months, 
          killBillProdId: plan.killBillProdId,
          stripeProdId: plan.stripeProdId,
          stripeTestId : plan.stripeTestId,
          price: price
         });
        }
      },
      setLoaded() {
        patchState(state, { loaded: true });
      },
      setLoading() {
        patchState(state, { loaded: false });
      },
      logout() {
        patchState(state, {});
      },
      setProductId(productId){
        patchState(state, {selectedPlan:productId, productId:productId})
      },
      updateAccountInfo(form){
        patchState(state, {
          agency_name: form.agency_name,
          agency_id: form.agency_id,
          email: form.email,
          firstName: form.firstName,
          lastName: form.lastName,
          address1: form.address1,
          address2: form.address2,
          city: form.city,
          state: form.state,
          postalCode: form.postalCode,
          productId: form.productId
         });
        },

      updateCardInfo(form){
        patchState(state, {
          creditCard: form.creditCard,
          cardType: form.cardType,
          expDate: form.expDate,
          cvc: form.cvc,
          productId: form.productId,
          nameOnCard: form.nameOnCard,
        });
      },
      
      receivedPricing(catalogObject) {
        const highlightsText = catalogObject.highlightsText;
        const tenant_logo = catalogObject.tenant_logo;
        const videoText = catalogObject.videoText;
        const videoId = catalogObject.videoId;
        const imageId = catalogObject.imageId;
        const middleText = catalogObject.middleText;

        const products = catalogObject.products;

        const enterprise = products[4]

        const plusIndex = catalogObject.products.findIndex(
          (entry) => entry.name === 'Plus',
        );
        const plusEarlyAdopterIndex = catalogObject.products.findIndex(
          (entry) => entry.name === 'PlusEarlyAdopter',
        );
        const premierIndex = catalogObject.products.findIndex(
          (entry) => entry.name === 'Premier',
        );
        const enterpriseIndex = catalogObject.products.findIndex(
          (entry) => entry.name === 'LeadsAddOn',
        );
        const leadsAddOnIndex = catalogObject.products.findIndex(
          (entry) => entry.name === 'Enterprise',
        );
        const premier = products[premierIndex];
        const earlyAdopter = products[plusEarlyAdopterIndex];
        const plus = products[plusIndex];

        // Find Index on these
        console.log("premier", premier);
        console.log("earlyAdopter", earlyAdopter);
        console.log("plus", plus);



        // Filter out Trial.
        const nonTrialPremierYearly =  premier.plans[0].phases.filter(phase=>{return phase.type!=='TRIAL'})[0]
        const nonTrialPremierMonthly =  premier.plans[1].phases.filter(phase=>phase.type!=='TRIAL')[0]
        const premierYearly = nonTrialPremierYearly.prices[0].value;
        const premierMonthly = nonTrialPremierMonthly.prices[0].value;

        const premierYearDiscount = 1-((premierYearly/12)/premierMonthly)
        const premierYearDiscountPercent = Math.floor(premierYearDiscount*100)
        const premierYearlyTotal = premierYearly;
  
        //const earlyAdopterYearly = earlyAdopter.plans[0].phases[0].prices[0].value;
        const nonTrialEarlyAdopterYearly =  earlyAdopter.plans[0].phases.filter(phase=>phase.type!=='TRIAL')[0]
        const nonTrialEarlyAdopterMonthly =  earlyAdopter.plans[1].phases.filter(phase=>phase.type!=='TRIAL')[0]
        console.log("nonTrialEarlyAdopterMonthly", nonTrialEarlyAdopterMonthly)
        const earlyAdopterMonthly = nonTrialEarlyAdopterMonthly.prices[0].value;

        const earlyAdopterYearDiscount = 1-((nonTrialEarlyAdopterYearly/12)/earlyAdopterMonthly)  
        const earlyAdopterYearDiscountPercent = Math.floor(earlyAdopterYearDiscount*100)
        const earlyAdopterYearlyTotal= nonTrialEarlyAdopterYearly;
      
        const nonTrialPlusYearly =  plus.plans[0].phases.filter(phase=>phase.type!=='TRIAL')[0]
        const nonTrialPlusMonthly =  plus.plans[1].phases.filter(phase=>phase.type!=='TRIAL')[0]

        

        const plusYearly = nonTrialPlusYearly.prices[0].value;
        const plusMonthly = nonTrialPlusMonthly.prices[0].value;
        const plusYearDiscount = 1-((plusYearly/12)/plusMonthly)
        const plusYearDiscountPercent = Math.floor(plusYearDiscount*100)
        const plusYearlyTotal = plusYearly;
  
        patchState(state, {
          highlightsText: highlightsText,
          tenant_logo: tenant_logo,
          catalogProducts: products,
          videoText: videoText,
          videoId: videoId,
          imageId: imageId,
          middleText: middleText,
          entities:{
          Premier:{
            min_seats: 1,
            optionsText: premier.optionsText,
            title:'Premier',
            date_time: new Date(),
            order: 0,
            name: 'Premier',
            uuid: '',
            discount_code: '', 
            display_text: '',
            months: 12,
            unit_monthly_price: premierMonthly, 
            unit_yearly_price: premierYearlyTotal,
            discount_number: premierYearDiscountPercent,
          },
          PlusEarlyAdopter:{
            optionsText: earlyAdopter.optionsText,
            min_seats: 1,
            title:'Plus',
            date_time: new Date(),
            order: 1,
            name: 'PlusEarlyAdopter',
            uuid: '',
            discount_code: '',
            display_text: '',
            months: 12,
            unit_monthly_price: earlyAdopterMonthly, 
            unit_yearly_price: earlyAdopterYearlyTotal,
            discount_number: earlyAdopterYearDiscountPercent,
          },
          Plus:{
            optionsText: plus.optionsText,
            min_seats: 1,
            title:'Plus',
            date_time: new Date(),
            order: 1,
            name: 'Plus',
            uuid: '',
            discount_code: '',
            display_text: '',
            months: 12,
            unit_monthly_price: plusMonthly, 
            unit_yearly_price: plusYearlyTotal,
            discount_number: plusYearDiscountPercent,
          },
          Enterprise:{
            optionsText: enterprise.optionsText,
            min_seats: 1,
            title:'Enterprise',
            date_time: new Date(),
            order: 2,
            name: 'Enterprise',
            uuid: '',
            discount_code: '',
            display_text: '',
            months: 12,
            unit_monthly_price: undefined, 
            unit_yearly_price: undefined,
            discount_number: undefined,
          },
        }
      })
      },
      async hoot(email: string, password: string) {
        // Placeholder
        //patchState(state, { loading: true })
      },
    }))
  );
}
